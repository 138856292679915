body {
    background-color: #FFFFFF;
}

.greyCard {
    background-color: #eff2f7;

}

.main-content {
    background-color: #FFFFFF;
    color: #1A1C27
}

.btn-outline-primary:hover {
    background-color: #0bb197 !important;

    color: white !important;
}

.btn-gold {
    background-color: #C1A036 !important;
    color: #1A1C27 !important;
}

.btn-outline-gold {
    background-color: transparent !important;
    color: #C1A036 !important;
}

.btn-outline-danger,
.btn-outline-primary,
.btn-outline-info,
.btn-outline-secondary {
    background-color: transparent !important;
}

.btn-outline-danger:hover .btn-outline-primary:hover,
.btn-outline-info:hover,
.btn-outline-secondary:hover {
    color: #505d69 !important;
}

.homePageBody {
    background-color: #FFFFFF;
    color: #1A1C27
}

#homeBanner {
    width: 100%;
    background-color: #1A1C27;
    color: #EEEEEE !important;

    border-top: 1px solid #EEEEEE;

}

#homeBanner h1 {
    font-size: 4em;
    color: #EEEEEE !important
}

#homeBanner h2 {
    font-size: 3.5em;
    color: #EEEEEE !important
}

#homeBanner p {
    font-size: 1.5em;
    line-height: 1.8em;
    color: #EEEEEE !important
}


#homeFooter {
    padding: 0.5em;
    height: 3em;
    text-align: center;
    font-size: 0.8em;
    color: #EEEEEE !important;
    background-color: #1A1C27;

}

#HomeSecondaryContent {
    color: #EEEEEE !important;
    background-color: #1A1C27;

}

#homePageContactForm h1,
#homePageContactForm h2,
#homePageContactForm h3,
#homePageContactForm h4,
#homePageContactForm h5,
#homePageContactForm h6,
#HomeSecondaryContent h1,
#HomeSecondaryContent h2,
#HomeSecondaryContent h3,
#HomeSecondaryContent h4,
#HomeSecondaryContent h5,
#HomeSecondaryContent h6,
#homePageContactForm.inputForm {
    color: #D1D1D6 !important;
}



a.dark {
    color: #fcb92c !important
}

/* Dark Mode */
.darkMode,
.darkMode .main-content,
#root .darkMode,
#layout-wrapper .darkMode .darkMode .page-content {
    background-color: #000000 !important;
    /*#202124*/
    color: #D1D1D6 !important;

}

#layout-wrapper {
    min-height: 100% !important
}

/* Text color in dark mode */
.darkMode p,
.darkMode h1,
.darkMode h2,
.darkMode h3,
.darkMode h4,
.darkMode h5,
.darkMode h6 {
    color: #D1D1D6 !important;
}

/* Apex Charts text color */
.apex-charts text {
    fill: rgb(80, 93, 105) !important;
}

/* Line break handling */
.lp-copy-sel__option,
.lp-copy-sel__single-value {
    white-space: pre-wrap !important;
}

/* Dark Mode card styles */
.navbar-brand-box .darkMode,
.darkMode .navbar-header,
.darkMode .card,
.darkMode .card-body,
.darkMode .card-body table,
.darkMode .card-body p,
.darkMode .card-body h5,
.darkMode .text-info,
.darkMode .tabMuiTableBody-root,
.logo-dark .darkMode,
#page-topbar .darkMode,
.darkMode .simplebar-wrapper,
.darkMode .vertical-menu {
    background-color: #000000 !important;
    color: #8E8E93 !important;
    border-radius: 10px !important;
}

.alert-container {

    height: 100%;
    max-height: 300px;
    overflow: auto !important;
    display: flex !important;

    flex-direction: column-reverse !important;

}

.darkMode .table,
.darkMode .table td,
.darkMode .table th,
.dark-mode .form-control,
.dark-mode .form-control input,
.darkMode table thead th,
.darkMode .input-group-text,
.darkMode .select2-selection,

#sidebar-menu .darkMode,

#sidebar-menu .darkMode ul {
    background-color: #000000 !important;
    /*#2C2C2E*/
    border-color: #AEAEB2 !important;
    color: #AEAEB2 !important;
}

table,
table tbody tr,
table th,
table thead th {
    background-color: transparent !important;
    color: #000000 !important;
}


.darkMode table tbody tr:hover td {
    background: #000000 !important;
}

.darkMode .page-content {

    border-left: 1px dashed #3A3A3C !important;
}

.darkMode .card-body {
    border: 1px dashed #3A3A3C !important;
}

moondance-input:checked {
    background-color: #fcb92c;
}

input {
    background-color: transparent !important;

    color: inherit !important
}

.btn-primary,
.badge .bg-success {
    color: #FFFFFF !important;

}

.MoondanceTable table {
    background-color: transparent !important;
    display: block;
    overflow: auto;
}

.select2-dropdown {
    z-index: 10060 !important;
    /*1051;*/
}

.largeModal {
    min-width: 60% !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    background-color: transparent !important;
}

.subFilterDropdown {
    font-size: 14px;
    font-family: "Work Sans", sans-serif;
    display: block;
    font-weight: 400;
    margin-left: 25px;

}

.subFilter input {
    margin-right: 1em;

}

.ownersCheckboxDropdown {
    margin-right: 8px;
}

.danger-slider::-webkit-slider-thumb {
    background-color: red !important;
}

.danger-slider {
    background-color: blue;
}

.btn-outline-danger,
.btn-outline-primary,
.btn-outline-info,
.btn-outline-secondary {
    background-color: transparent !important;
}

.btn-outline-info:hover {
    background-color: #4aa3ff !important;
}

.btn-outline-danger:hover {
    background-color: #ff3d60 !important;
}

.btn-outline-primary:hover {
    background-color: #0bb197 !important;
    color: #000000 !important;

}

.btn-link:hover {
    background-color: #0bb197 !important;
}

.btn-outline-secondary:hover {
    background-color: #74788d !important;
}

.apexcharts-tooltip {
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 10;
}

.apexcharts-tooltip-title {
    font-size: 15px;
    margin-bottom: 4px;
}

.apexcharts-tooltip-series {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.apexcharts-tooltip-marker {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.equal-height-card {
    min-height: 90% !important;
    /* You can adjust this value as needed */
    display: flex;
    flex-direction: column;
}

.info {
    color: #4aa3ff !important;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pct-form {
    position: relative;
}

.pct-form::after {
    content: '%';
    /* Add the '%' symbol */
    position: absolute;
    top: 50%;
    /* Adjust the vertical position as needed */
    right: 10px;
    /* Adjust the horizontal position as needed */
    transform: translateY(-50%);
    pointer-events: none;
    /* Make the '%' symbol non-interactive */
}

@media (min-width: 1026px) {
    .mobileOnly {
        display: none !important;
    }
}


@media only screen and (max-width: 1026px) {
    #page-topbar {
        max-height: 2.5em;
        margin: 0px !important;

    }

    .navbar-header {
        max-height: 2.5em;
        margin: 0px !important;
    }

    .header-item {
        max-height: 2.5em !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;
        padding-top: 0px !important;
        font-size: 1em !important;
    }

    .navbar-brand-box {
        display: none !important
    }

    .page-content {
        padding-top: 3em !important;
    }

    .hideOnMobile {
        display: none !important;
    }

    .mobileOnly {
        display: visible !important;
    }


    .ms-2 {
        margin-top: 0.2em !important;
    }

    .vertical-menu {
        top: 2em !important;
        width: 100% !important;
    }

    .homeContainer {
        padding: 2em !important;
    }

    .publicHomePage li {
        padding-bottom: 1em;
        padding-top: 1em;
    }

    #homeBanner {

        width: 100%;
        margin-top: 10px;
    }

    #homeBanner h2 {
        line-height: 1.6em;
        font-size: 1.4em;
    }

    #homeBanner p {
        line-height: 1.8em;
        font-size: 1.1em;
    }

    

    #home-topbar {
        background-color: #1A1C27;
        height: 70px;
        width: 100%;
        max-width: 100%;
    }

    #home-topbar img {
        max-height: 50px;
    }




    #homeCarousel img {
        margin-bottom: 2em;
    }
}

@media only screen and (min-width: 1026px) {



    #home-topbar {
        background-color: #1A1C27;
        color: #FFFFFF;
        height: 80px;
    }

    .publicHomePage li {
        padding-bottom: 1em;
        padding-top: 1em;
    }

    .homeContainer {
        padding: 10em;
        line-height: 2em;
    }

    #HomeSecondaryContent li {
        font-size: 1em;
        padding-top: 0.6em;
    }





    .homePageContactForm {
        margin-top: 10em;
        padding: 2em;
        margin-top: 2em;
        margin-bottom: 2em;

    }


    #homePageContactForm.inputForm {
        background-color: #1A1C27 !important;

    }

    #homeCarousel p {
        font-size: 1.2em;
        color: #1A1C27 !important;
    }

    #homeCarousel h5 {
        font-size: 1.4em;
        color: #1A1C27 !important;
    }

    #homeCarousel img {
        max-width: 80%;
        margin-left: 10%
    }
}



.tableXlsImportCell {
    border: 1px solid #000000;
    padding: 0.5em;
}

.tableXlsImportCellDisabled {
    border: 1px solid #000000;
    padding: 0.5em;
    font-style: italic;
}

.tableXlsImportHead {

    border: 1px solid #000000;
    background-color: #78E5CD !important;
    margin: 0.1em;
    text-align: center;
}

.tablexlsImportHead th,
.tablexlsImportHead td {
    min-width: 50px;
    /* Ensure each cell has a minimum width */
    /* max-width: 200px; /* and a maximum width, adjust as needed */
    white-space: nowrap;
    /* Prevent text from wrapping */

}

.tablexlsImportHead thead th {
    position: sticky;
    top: 0;
    /* Adjust this value based on your needs */
    background-color: #fff;
    /* Set a background color to prevent see-through when scrolled */
    z-index: 1;
    /* Higher z-index ensures the header stays on top of other content */
    border: 1px solid #000000;

}

.tablexlsImportHead tbody tr,
.tablexlsImportHead th,
.tablexlsImportHead td {
    border: 1px solid black;
    /* Visual style */
}

.tableXlsImportColHead {
    border: 1px solid #000000;
    background-color: #78E5CD !important;
    margin: 0.1em;
    text-align: center;
    position: sticky;
    width: 2em;
    left: 0;
    z-index: 1;
}

.scrollable-table {
    max-height: 400px;
    overflow: auto;
    /* Allows scrolling */
}

.badge .bg-danger {
    background-color: #FF5E78 !important;
}



@media print {

    body,
    * {
        font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    }

    body {
        margin: 3px;
        font-size: medium;
        color: #000;
        background-color: #fff;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }

    .btn {
        display: none !important;
        ;
    }

    .card {
        max-width: 100% !important;
    }

    .MoondanceTable {
        max-width: 100% !important;
    }
}

.pdfReport {
    position: relative;
    width: 210mm;
    height: 297mm;
    padding: 0mm;
    margin: 0mm;
    background-color: white;
    border: 1px solid black;
    overflow: scroll;
    display: hidden;

}

.pdfReport_landscape {
    position: relative;
    width: 297mm;
    height: 210mm;
    padding: 0mm;
    margin: 0mm;
    background-color: white;
    border: 1px solid black;
    overflow: scroll;
    display: hidden;

}

.pdf_right {
    float: right;
    padding: 1mm;
    position: absolute;
    right: 2mm
}

.pdf_left {
    float: left;
    padding: 1mm;
    position: absolute;
    left: 2mm
}

.pdf_header {
    width: 100%;
    padding-bottom: 2mm;
    box-sizing: border-box;
    break-inside: avoid;
    /* Prevent splitting header */
    margin-bottom: 5mm;
    display: block;
    min-height: 15mm;
}

.pdf_footer {
    width: 100%;
    min-height: 20mm;
    padding-top: 2mm;
    box-sizing: border-box;
    break-inside: avoid;
    /* Prevent splitting footer */
    margin-top: 5mm;
    page-break-after: always;
    /* New page after footer */

}

.lineBreaker {
    margin-bottom: 20mm;
    page-break-after: always;
}

.pdfReport h1 {
    font-size: 35px;
    font-weight: 350;

    line-height: 25px;
    padding: 0;
}

.pdfReport h2 {
    font-size: 22px;
    font-weight: 450;

}

.pdfReport h3 {
    font-size: 22px;
    font-weight: 350;

}

.pdfReport sub {
    font-size: 10px;
    font-weight: 450;

}


.MoondanceTable_print table {
    width: 100%;
    padding: 2mm;
    border-collapse: collapse;
    line-height: 1.8em;
    background: white !important;
    font-size: 10px;
}

.MoondanceTable_print th {
    font-size: 12px;
    text-align: left;
    border-bottom: 1px solid #767676;

    font-weight: 600;
}

.MoondanceTable_print tr {
    padding: 0 !important;
    font-size: 10px;
    border-bottom: 1px dashed #767676;
}

.MoondanceTable_print td {
    font-size: 10px;
    padding-right: '5mm' !important;
    text-align: right;
    padding-bottom: '2mm' !important;
    padding-top: '2mm' !important;
}



.public_container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 01rem;
}

.public_section {
    padding: 4rem 0;

}

.public_button {
    display: inline-block;
    padding: 12px 24px;
    background-color: #d4af37;
    color: var(--dark-color);
    font-weight: 600;
    text-decoration: none;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1rem;
    letter-spacing: 0.5px;
}

.public_button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    opacity: 0.9;
}

.public_button-secondary {
    background-color: transparent;
    color: #d4af37;
    border: 2px solid #d4af37;
}



.public_header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.public_logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: #1a1c27;
    text-decoration: none;
}

.public_logo span {
    color: #d4af37;
}

/* Hero Section */
.public_hero {
    background: #1a1c27;
    color: #ffffff;
    padding: 4rem 0;
    text-align: center;
    position: relative;
}

.public_hero-content {
    position: relative;
    z-index: 2;
    max-width: 800px;
    margin: 0 auto;
}

.public_hero h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.public_hero p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
}

.public_hero .public_button {
    font-size: 1.1rem;
    padding: 15px 30px;
}

.public_social-proof {
    margin-top: 2rem;
    font-style: italic;
    opacity: 0.9;
}

/* Problem Statement */
.public_problem {
    background-color: #f5f5f7;
    padding-top: 2em;
}

.public_pain-points {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin: 2rem 0;
}

.public_pain-point {
    background-color: white !important;
    padding: 2rem;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-top: 4px solid #d4af37;
    transition: transform 0.3s ease;
}

.public_pain-point:hover {
    transform: translateY(-5px);
}

.public_pain-point h3 {
    color: #d4af37 !important;
}

.public_statistics {
    margin-top: 2rem;
    border-top: 1px solid #e5e5e5;
    padding-top: 2rem;
}

.public_statistic {
    font-size: 1.1rem;
    margin-bottom: 1rem;
}

/* Solution Overview */
.public_solution {
    text-align: center;
}

.public_features-brief {
    margin: 2rem 0;
}

.public_feature-brief {
    margin-bottom: 1rem;
    font-size: 1.1rem;
}

.public_feature-brief strong {
    color: #1a1c27;
}

.public_video-container {
    margin: 2rem auto;
    max-width: 800px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Key Features */
.public_features-detailed {
    background-color: white;
}

.public_feature-card {
    margin-bottom: 4rem;
    padding: 2rem;
    border-radius: 4px;
    background-color: #f8f9ff;
    border-left: 5px solid #d4af37;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.public_feature-card:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
}

.public_feature-card h3 {
    color: #d4af37;
    margin-bottom: 0.5rem;
}

.public_feature-card .public_subtitle {
    font-weight: 600;
    margin-bottom: 1rem;
}

/* Proof Points */
.public_proof-points {
    background-color: #f5f5f7;
}

.public_capabilities {
    margin-bottom: 2rem;
}

.public_capability-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    list-style-type: none;
}

.public_capability-list li {
    padding: 1rem;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.public_comparison-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 2rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
}

.public_comparison-table th,
.public_comparison-table td {
    padding: 1rem;
    border: 1px solid #e5e5e5;
    text-align: left;
}

.public_comparison-table th {
    background-color: #d4af37;
    color: var(--dark-color);
    font-weight: bold;
}

.public_comparison-table tr:nth-child(odd) {
    background-color: #f9f9f9;
}

/* CTA Section */
.public_cta-section {
    text-align: center !important;
    background: #1a1c27 !important;
    position: relative !important;
}

.public_cta-section .public_container {
    position: relative;
    z-index: 2;
}

.public_form-container {
    max-width: 600px;
    margin: 2rem auto;
    background-color: white;
    padding: 2rem;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.public_contact-form {
    display: grid;
    gap: 1rem;
}

.public_form-group {
    text-align: left;
}

.public_form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333333;
    font-weight: 600;
}

.public_form-control {
    width: 100%;
    padding: 12px;

    color: #333333;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    font-size: 1rem;
}

/* FAQ Section */
.public_faq-section {
    background-color: #f8f9ff;
}

.public_faq-container {
    max-width: 800px;
    margin: 0 auto;
}

.public_faq-item {
    margin-bottom: 2rem;
}

.public_faq-item h3 {
    color: #1a1c27;
    margin-bottom: 0.5rem;
}

/* Final CTA */
.public_final-cta {
    text-align: center;
    padding: 4rem 0;
}

.public_final-cta h2 {
    margin-bottom: 1rem;
}

.public_final-social-proof {
    margin-top: 2rem;
    font-style: italic;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}



.public_footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.public_footer-logo {
    margin-bottom: 1rem;
}

.public_footer-links {
    margin-bottom: 1rem;
}

.public_footer-links a {
    color: #d4af37;
    margin: 0 0.5rem;
    text-decoration: none;
    transition: opacity 0.3s ease;
}

.public_footer-links a:hover {
    opacity: 0.8;
}

.public_copyright {
    font-size: 0.9rem;
    opacity: 0.8;
}


@media (max-width: 768px) {
    .public_hero h1 {
        font-size: 2.2rem;
    }

    .public_hero p {
        font-size: 1.1rem;
    }

    .public_section {
        padding: 2rem 0;
    }

    .public_pain-points,
    .public_capability-list {
        grid-template-columns: 1fr;
    }
}
.public_section {
    width:100% !important;
}

.public_section h1,
.public_section h2,
.public_section h3,
.public_section h4,
.public_section h5,
.public_section h6 {
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1rem;
}

.public_section h1 {
    font-size: 2.5rem;
}

.public_section h2 {
    font-size: 2rem;
}

.public_section h3 {
    font-size: 1.5rem;
}

.public_btn-gold {
    padding: 12px 24px;
    background-color: #C1A036 !important;
    color: #1A1C27 !important;
    font-weight: 500;
    font-size: 1.1rem;
    border-radius: 0%;
}

.public_btn-outline-gold {
    background-color: transparent !important;
    color: #C1A036 !important;
    border: 2px solid #C1A036 !important;
    border-radius: 0%;
    font-weight: 500;
    font-size: 1.1rem;
}

@media only screen and (min-width: 1026px) {

    .public_btn-outline-gold {
        padding: 12px 24px;
    }

    .public_btn-gold {
        padding: 12px 24px;
    }
}

